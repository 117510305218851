import { authedFetch, kc } from '@agnet-aviation/auth'
import singleSpaHtml from '../../utils/single-spa-html'
import { gdprTemplate } from './html'
import { API_URL, API_ENDPOINTS } from '../../constants/constants'

const htmlLifecycles = singleSpaHtml({
  template: gdprTemplate,
})

const mount = async (props: any) => {
  await (await htmlLifecycles).mount(props)

  document.querySelector('#accept-gdpr')?.addEventListener('click', async () => {
    try {
      await authedFetch(`${API_URL}/${API_ENDPOINTS.ACCEPT_GDPR}`, {}, 'PATCH')
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  })
  document.querySelector('#decline-gdpr')?.addEventListener('click', async () => {
    kc.logout()
  })
}

const unmount = async (props: any) => {
  await (await htmlLifecycles).unmount(props)
}

const bootstrap = async () => {
  await (await htmlLifecycles).bootstrap()
}

export const gdprAcceptApp = {
  mount,
  unmount,
  bootstrap,
}
