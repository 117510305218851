export type All = '*'

export enum AccessRole {
  TACTICAL_ADMIN = 'tactical_admin',
  SUPER_ADMIN = 'super_admin',
  MISSION_MANAGER = 'mission_manager',
}

export interface AccessRights {
  access: (AccessRole | All)[]
  exclude: (AccessRole | All)[]
}

const applicationsRoleRequirements: { [key: string]: AccessRights } = {
  '@agnet-aviation/Login': { access: ['*'], exclude: [] },
  '@agnet-aviation/Navbar': { access: ['*'], exclude: [] },
  '@agnet-aviation/monitoring': { access: ['*'], exclude: [AccessRole.SUPER_ADMIN] },
  '@agnet-aviation/communication': { access: ['*'], exclude: [AccessRole.SUPER_ADMIN] },
  '@agnet-aviation/organization': {
    access: [AccessRole.TACTICAL_ADMIN, AccessRole.MISSION_MANAGER],
    exclude: [],
  },
  '@agnet-aviation/subscription-management': { access: [AccessRole.SUPER_ADMIN], exclude: [] },
  '@agnet-aviation/user-preferences': { access: ['*'], exclude: [] },
}

export default applicationsRoleRequirements
