const buttonBase = `
display: flex;
margin: auto;
text-transform: uppercase;
border-radius: 4px;
padding: 8px 22px 8px 22px;
font-weight: 500;
width: 183px;
justify-content: center;
cursor: pointer;
font-size: 15px;
line-height: 26px;
letter-spacing: 0.46px;
white-space: nowrap;
font-family: Roboto;
`

const buttonPrimary = `
${buttonBase}
color: white;
background-color: rgba(0, 85, 135, 1);
border-color: rgba(0, 85, 135, 1);
`

const buttonSecondary = `
${buttonBase}
color: rgba(0, 85, 135, 1);
background-color: white;
border-color: rgba(0, 85, 135, 1);
border-width: 1px;
`

const paragraph = `
font-size: 14px;
font-weight: 400;
line-height: 16.41px;
`

export const gdprTemplate = `
<div style="
max-height: 100vh;
height: 100vh;
max-width: 100vw;
width: 100vw;
background-color: rgba(0, 32, 91, 1);
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
font-family: Roboto;
">
    <div style="
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    padding: 24px 18px 18px 24px;
    margin: 18px;
    overflow-y: auto;
    max-height: 80%;
    ">
        <span style="
        ${paragraph}
        display: block;
        font-weight: 600;
        ">Data Protection Statement</span>
        <br/>
        <span style="
        display: block;
        ${paragraph}
        ">Agnet Turnaround® is committed to protecting the rights of individuals in line with the Data
        Protection Regulations of the European Parliament and of the Council on the protection of
        natural persons with regard to the processing of personal data and on the free movement of
        such data [depending on the Customer profile, reference (EU) 2016/679, (EU) 2016/680 and/ or (EU)
        2018/1725] as well as each applicable national Personal Data Protection Laws and regulations
        collectively referred as “Data Protection Laws and Regulations”.
        <br/><br/>
        Within this framework, Agnet Turnaround® will process only the name, user name, e-mail, phone
        number, the login, the password and the activity log of the user in order to access the
        Agnet Turnaround® tool and manage the access rights.
        The personal data will be stored no longer than necessary and will not be transferred.
        Only the Administrator of the system will have access to this data.
        <br/><br/>
        Agnet Turnaround® integrates “privacy by design” in order to protect the personal data it processes
        and in order to be compliant with the EU 2016/679, EU 2016/680 and/ or EU 2018/1725 [see note
        above]."</span>
        <br/>
        <button style="
        ${buttonPrimary}
        "
        id="accept-gdpr"
        >I read and I accept</button>
        <br/>
        <button style="
        ${buttonSecondary}
        "
        id="decline-gdpr"
        >I do not accept</button>
    </div>
</div>
`
