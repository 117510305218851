import { registerApplication, start } from 'single-spa'
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout'
import microfrontendLayout from './microfrontend-layout.html'

import { kc, authedFetch } from '@agnet-aviation/auth'
import applicationsRoleRequirements from './constants/applicationsRoleRequirements'
import singleSpaHtml from './utils/single-spa-html'
import { API_URL, API_ENDPOINTS } from './constants/constants'
import { gdprAcceptApp } from './apps/gdpr'
import { setPrivateConfig, setPublicConfig } from './utils/window'

const noAccessPage = singleSpaHtml({
  template: `
	<div
		style="
		background-color: white;
		font-family: Roboto;
		text-align: center;
		padding-top: 40px;
	">
		Not Authorized
	</div>`,
})

const getRoutes = (gdprAccepted: boolean) => {
  if (!gdprAccepted) {
    return `
    <single-spa-router>
      <route default>
        <application name="@agnet-aviation/accept-gdpr"></application>
      </route>
    </single-spa-router>
    `
  }
  return microfrontendLayout
}

// Load auth module first
System.import('@agnet-aviation/auth').then(async () => {
  // Activate the layout engine once keycloak is loaded
  try {
    await setPublicConfig()

    const authenticated = await kc.initialize()
    if (authenticated) {
      await setPrivateConfig()

      // check if user has accepted GDPR
      const { gdprAccepted, id } = await authedFetch(
        `${API_URL}/${API_ENDPOINTS.CURRENT_USER_PROFILE}`
      )

      const routes = constructRoutes(getRoutes(gdprAccepted))

      window.elasticApm?.setUserContext({ id })

      // check which MFEs the user is allowed to access
      const applications = constructApplications({
        routes,
        loadApp: (app) => {
          if (app.name === '@agnet-aviation/accept-gdpr') {
            return Promise.resolve(gdprAcceptApp)
          }
          const hasAccess = kc.hasAccessToRoles(applicationsRoleRequirements[app.name].access)
          const isExcluded = kc.hasAccessToRoles(
            applicationsRoleRequirements[app.name]?.exclude ?? []
          )
          if (hasAccess && !isExcluded) {
            return System.import(app.name)
          } else if (app.name === '@agnet-aviation/communication') {
            return singleSpaHtml({ template: '<div></div>' })
          } else {
            return noAccessPage
          }
        },
      })
      const layoutEngine = constructLayoutEngine({ routes, applications })

      applications.forEach(registerApplication)

      layoutEngine.activate()
      start()
    } else {
      kc.login()
    }
  } catch (err: any) {
    console.error(err)

    if (err.message.includes('Fatal config error:')) {
      // render error page with single spa
      document.body.innerHTML = `
        <div style="font-family: Roboto; text-align: center; padding-top: 40px">
          <h3>${err.message}</h3>
        </div>
      `
    } else {
      kc.logout() // mainly if user profile fetch fails -> logout
    }
  }
})
